<template>
  <b-modal
    ref="newMembers"
    size="lg"
    title="Neue Mitglieder"
    hide-footer
    centered
  >
    <div>
      <p>
        Wir nehmen aktuell wieder Mitglieder für unsere aktiven Angler und unsere
        Jugend auf.
        Falls Sie weitere Fragen zur Aufnahme in unserem Verein, den
        Mitgliedsbeiträgen etc. haben, senden Sie uns gerne eine E-Mail und wir
        werden Ihnen so bald wie möglich antworten.
      </p>
      <hr />
      <p>
        Hier finden Sie weiterführende Informationen zu unserem Verein wie die
        aktuelle Satzung oder eine Beitrittsinformation für aktive Angler zum
        Herunterladen.
      </p>
      <div class="d-flex flex-column justify-content-end flex-sm-row">
        <b-button
          :href="vereinssatzungLink"
          target="_blank"
          variant="primary"
          class="mr-0 mb-1 mr-sm-2 mb-sm-0"
          >Vereinssatzung</b-button
        >
        <b-button
          :href="beitrittsinformationLink"
          target="_blank"
          variant="success"
          >Beitrittsinformation</b-button
        >
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
	data () {
    return {
      vereinssatzungLink: '',
      beitrittsinformationLink: ''
    }
  },
  created () {
    this.getPictureLinks()
  },
  mounted() {
    this.open();
  },
  watch: {
    "$route.name": function () {
      this.open();
    },
  },
  methods: {
    open() {
      if (this.$route.name === "Home") {
        this.$refs.newMembers.show();
      }
    },
		async getPictureLinks () {
      this.vereinssatzungLink = await this.$firebase.storage().ref().child('Vereinssatzung.pdf').getDownloadURL()
      this.beitrittsinformationLink = await this.$firebase.storage().ref().child('Beitrittsinformationen.pdf').getDownloadURL()
    }
  },
};
</script>
