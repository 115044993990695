<template>
  <main class="my-4 py-3">
    <h1 class="d-none">Unsere Gewässer</h1> <!-- SEO -->
    <b-container>
      <b-row>
        <!-- Zeile 1: Pokalangeln -->
        <b-col cols="12" class="mb-4">
          <b-card
            :img-src="require('@/assets/see_laake.jpeg')"
            img-alt="Card image"
            img-right
            class="flex-md-row-reverse flex-sm-column-reverse flex-column-reverse"
          >
            <h5 class="font-weight-bold">Heidenfelder See (See Laake)</h5>
            <b-card-text>
              <span>
                Der See Laake ist Eigentum des SAC und liegt am Rande des
                Naturschutzgebietes bei Heidenfeld. Er besitzt eine Fläche von
                ca. 5 Hektar. Jedes aktive Mitglied erhält hierfür eine
                Jahreskarte und zusätzlich eine Gastkarte. In diesem See
                befinden sich Spiegelkarpfen, Hechte, Zander, Aale, Barsche,
                Brassen, Rotaugen und Rotfedern.
              </span>
            </b-card-text>
          </b-card>
        </b-col>
        <!-- Zeile 2 Freundschaftsangeln -->
        <b-col cols="12" class="mb-4">
          <b-card
            :img-src="require('@/assets/wern.jpeg')"
            img-alt="Card image"
            img-right
            class="flex-md-row-reverse flex-sm-column-reverse flex-column-reverse"
          >
            <h5 class="font-weight-bold">Wern</h5>
            <b-card-text>
              <span>
                Die Wern ist ein Bach, der zwischen Rannungen und Pfersdorf
                entspringt und bei Wernfeld in den Main mündet. Eine Strecke von
                5 Kilometern zwischen Hain und Kronungen hat der SAC Ebenhausen
                gepachtet. Das Befischen ist jedoch nur von Mai bis Ende
                September gestattet.
                Zusätzlich findet zur Eröffnung der Angelsaison ein Anangeln
                statt, zu dem alle aktiven Mitglieder eingeladen sind, ebenso
                werden zwei Aalangeln im Jahr veranstaltet. In der Wern sind
                hauptsächlich Bachforellen und Aale beheimatet.
              </span>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>

<script>
export default {
  mounted() {
    document.querySelector('meta[name=description]').content = 'Gewässer des Vereins'
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/app.scss';
@media (min-width: 768px) {
  .card {
    img {
      max-width: 480px !important;
    }
  }
}
</style>
